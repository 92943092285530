<template>
    <div class="index">
        <router-view/>
        <van-tabbar v-model="active" class="bottom_tabbar">
            <van-tabbar-item name="Home" to="/">
                <span>我要购买</span>
                <img slot="icon" slot-scope="props" :src="props.active ? icon.active : icon.inactive" />
            </van-tabbar-item>
            <van-tabbar-item name="Sale" to="/sale">
                <span>我要出售</span>
                <img slot="icon" slot-scope="props" :src="props.active ? icon.active2 : icon.inactive2" />
            </van-tabbar-item>
            <van-tabbar-item v-if="isShow" name="trackit" to="/helper">
                <span>账号助手</span>
                <img slot="icon" slot-scope="props" :src="props.active ? icon.active3 : icon.inactive3" />
            </van-tabbar-item>
            <van-tabbar-item name="Account" to="/account">
                <span>我的账户</span>
                <img slot="icon" slot-scope="props" :src="props.active ? icon.active4 : icon.inactive4" />
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
export default {
    name:'homePage',
    data(){
        return {
            profession:1,
            value:'',
            active:'home',
            isActive:1,
            icon: {
                active: require("../assets/images/icon1_on.png"),
                inactive: require("../assets/images/icon1.png"),
                active2: require("../assets/images/icon2_on.png"),
                inactive2: require("../assets/images/icon2.png"),
                active3: require("../assets/images/icon3_on.png"),
                inactive3: require("../assets/images/icon3.png"),
                active4: require("../assets/images/icon4_on.png"),
                inactive4: require("../assets/images/icon4.png")
            },
            list: [],
            loading: false,
            finished: false,
            show:false,
            formDate:{
                value: '',
                columns: ['仙境传奇角色', '仙境传奇·火龙角色', '仙境·时光倒流版角色'],
                showPicker: false,
                bigPrice:'',
                smallPrice:'',
            },
            isTrue:true,
            isShow:true
        }
    },
    // beforeRouteEnter(to, from, next) {
    //     // console.log(this)
    //     var reg = new RegExp("(^|&)"+ 'a' +"=([^&]*)(&|$)");
    //     var r = window.location.search.substr(1).match(reg);
    //     // console.log(r)
    //     //  console.log(unescape(r[2]))
    //     //  if(r!=null)return unescape(r[2]); return null;
    //     // next();
    //     if(r!=null){
    //         localStorage.removeItem('token');
    //         localStorage.removeItem('username');
    //         next((vm) => {
    //             // console.log("vm", vm.$route.query.a);
    //             let arr = vm.$Base64.decode(vm.$route.query.a)
    //             // console.log(arr)
    //             arr = JSON.parse(arr)
    //             vm.$api.login.loginSdk(arr).then(res => {
    //                 if (res.code == 200) {
    //                     // console.log(vm.$route.query.a)
    //                     localStorage.setItem('token', 'Bearer '+ res.data.token);
    //                     localStorage.setItem('username', res.data.username);
    //                     let list
    //                     if(vm.$route.query.a){
    //                         list = vm.$Base64.decode(vm.$route.query.a)
    //                         // localStorage.setItem('a', list);
    //                         // list = JSON.stringify({password: 'M2QwZm1abnZmVVhJczMrZU5OK2xSUEFXMlFpTWVFZEtBamRNME…VIzZHZHYTMxTjA0K2dha2tYNjE0TVBiYWxHMnZuRWE2ZTI3Zw', server_id: '50001', type: '24', username: '3143635', seedname: '50001166441587511921',onsale_type:'1'})
    //                         sessionStorage.setItem('a', list)
    //                         list = JSON.parse(list).type
    //                         // console.log(list)
    //                         // localStorage.setItem('type', list);
    //                         sessionStorage.setItem('type', list);
    //                         const path = window.location.href.split('?')[0]
    //                         window.location.href=path
    //                     }
    //                     // this.$router.push('/');
    //                 }
    //             })
    //         });




    //         // let arr = this.$Base64.decode(this.$route.query.a)
    //         // arr = JSON.parse(arr)
    //         // this.$api.login.loginIn(arr).then(res => {
    //         //     if (res.code == 200) {
    //         //         localStorage.setItem('token', 'Bearer '+ res.data.token);
    //         //         localStorage.setItem('username', arr.username);
    //         //         // this.$router.push('/');
    //         //     }
    //         //     next();
    //         // }).catch(err => {
    //         //     next();
    //         //     console.log(err);
    //         // });
    //     }else{
    //         next();
    //     }
    //     // console.log("beforeRouteEnter", to);
    //     // next();
    // },
    created(){
        if(sessionStorage.getItem('type')=='24'){
            this.isShow = false
        }
    },
    watch: {
        $route: {
            immediate: true, // 一旦监听到路由的变化立即执行
            handler(to, from) {
                this.active = to.meta.tab;
            },
        },
    }
}
</script>
<style scoped>
.index{
    height: 100%;
}
.bottom_tabbar .van-tabbar-item--active{
    color: #FF4E00;
}
</style>